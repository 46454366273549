/*
Footer styles
*/

#Footer {
  display: flex;
  align-items: baseline;
  position: fixed;
  bottom: 0;
  width: 100vw;
  color: #898695;
  background-color: #382d39;
  padding: 0.5rem;
  font-size: 0.75rem;
  z-index: 50;
}

#Footer a, #Footer a:visited, #Footer a:focus {
  color: #898695;
  text-decoration: none;
}
#Footer a:hover {
  color: #f6f1f2;
}

#Footer div {
  flex: 1 1 33.33%;
  margin: 0 12px;
}

#Footer .Source {
  text-align: center;
}

#Footer .Feedback {
  text-align: right;
}

@media screen and (min-width: 768px) {
  #Footer {
    font-size: 0.85rem;
  }
}
