/*
TopGroups styles
*/

#TopGroups {
  grid-area: top-groups;
}

#TopGroups h2 {
  text-align: center;
}
