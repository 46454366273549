/*
Common table styles
*/

table {
  /* table-layout: fixed; */
  width: 100%;
  margin-top: 24px;
  border-collapse: collapse;
}

thead {
  border-bottom: 3px solid #898695;
}

thead th {
  text-align: left;
  padding: 6px;
}

tbody td {
  padding: 6px;
  border-bottom: 1px solid #898695;
}

tbody tr:last-child td {
  border-bottom: none;
}
