/*
TopGroups styles
*/

#TopCallsigns {
  grid-area: top-calls;
}

#TopCallsigns h2 {
  text-align: center;
}
