/*
Main app styles

Theme: http://colormind.io/bootstrap/
#f6f1f2 #de9e8e #dd4b39 #898695 #382d39
*/

* {
  box-sizing: border-box;
}

a, a:visited {
  color: #dd4b3988;
  text-decoration: none;
}
a:focus, a:hover {
  color: #dd4b39;
}

#App {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto auto;
  grid-template-areas:
    "banner"
    "controls"
    "actives"
    "top-groups"
    "top-calls";
  gap: 24px;
  margin-top: 4rem;
  margin-bottom: 3rem;
  padding: 0 12px;
}

#Banner {
  grid-area: banner;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: baseline;
  gap: 0 0.5rem;
  padding: 0.5rem;
  margin: 0 -12px;
  background-color: #de9e8e;
}

#Banner p {
  text-align: center;
  margin: 0;
}

#Banner a, #Banner a:visited {
  color: #480a10cc;
  font-weight: 700;
}

#Banner a:focus, #Banner a:hover {
  color: #480a10;
  font-weight: 700;
}

.Button {
  display: inline-block;
  color: #ffffff;
  background-color: hsl(0, 0%, 60%);
  border: none;
  border-radius: 3px;
  padding: 12px 24px;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  transition:
    background-color 250ms ease-in;
}
.Button:hover {
  background-color: hsl(0, 0%, 65%);
}
.Button:focus {
  outline: none;
}

.Button.Primary {
  background-color: hsla(0, 0%, 100%, 0.1);
}
.Button.Primary:hover {
  background-color: hsla(0, 0%, 100%, 0.2);
}

.Label {
  display: inline-block;
  color: #ffffff;
  background-color: hsl(0, 0%, 60%);
  border: none;
  border-radius: 6px;
  padding: 16px 12px;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
}

.Label.Success {
  background-color: hsl(81, 33%, 43%);
}

.Label.Danger {
  background-color: hsl(4, 90%, 58%);
}

.Subtext {
  color: #898695;
  font-size: 0.9em;
}

@media screen and (min-width: 1440px) {
  #App {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
      "banner banner"
      "controls controls"
      "actives actives"
      "top-groups top-calls";
  }
}
