/*
Header styles
*/

#Header {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 3.75rem;
  color: #f6f1f2;
  background-color: #dd4b39;
  padding: 0 1rem;
  z-index: 100;
}

#Header > h1 {
  flex: 1 0 auto;
  font-size: 1rem;
  margin: 0;
}

#Header > h1 > .Title {
  margin-left: 0.5rem;
}

#NavMenu {
  position: absolute;
  top: 3.75rem;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  font-weight: 500;
  background-color: #dd4b39;
  font-size: 1.2rem;
  visibility: hidden;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

#NavMenu.Open {
  visibility: visible;
  opacity: 1;
}

#NavMenu .NavMenuItem {
  height: 3.75rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  color: #f6f1f2;
  transition: background-color 200ms ease-in-out;
}

#NavMenu .NavMenuItem:hover {
  background-color: #F6F1F222;
}

#NavMenu .NavMenuItem > svg {
  margin-right: 0.5rem;
}

@media screen and (min-width: 375px) {
  #Header > h1 {
    font-size: 1.2rem;
  }

  #Header > h1 > .Title {
    margin-left: 0.75rem;
  }
}

@media screen and (min-width: 425px) {
  #Header > h1 {
    font-size: 1.3rem;
  }

  #Header > h1 > .Title {
    margin-left: 0.75rem;
  }
}

@media screen and (min-width: 768px) {
  #Header > h1 {
    font-size: 1.5rem;
  }

  #Header > h1 > .Title {
    margin-left: 1rem;
  }

  #NavMenu {
    position: static;
    top: unset;
    left: unset;
    right: unset;
    flex-flow: row nowrap;
    visibility: visible;
    opacity: 1;
  }

  #NavMenu .NavMenuItem {
    padding: 0 0.75rem;
  }
}
